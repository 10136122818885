module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/home/runner/work/blog/blog/node_modules/@raae/gatsby-remark-oembed","id":"5d933264-916d-54e1-bae7-0edbfe9ed1ee","name":"@raae/gatsby-remark-oembed","version":"0.1.1","pluginOptions":{"plugins":[]},"nodeAPIs":["onPreBootstrap"],"browserAPIs":["onInitialClientRender","onRouteUpdate"],"ssrAPIs":["onRenderBody"]}],"extensions":[".mdx",".md"],"remarkPlugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-autolink-headers","options":{"offsetY":"500","icon":false,"className":"autolink-headers"}},{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"noopener noreferrer"}},"gatsby-remark-relative-images",{"resolve":"gatsby-remark-images","options":{"maxWidth":1400,"quality":90,"linkImagesToOriginal":false,"withWebp":true,"withAVIF":true,"tracedSVG":true,"showCaptions":true,"wrapperStyle":""}},"gatsby-remark-katex","gatsby-remark-graphviz","gatsby-remark-code-titles","gatsby-remark-figure-caption",{"resolve":"gatsby-remark-prismjs","options":{"classPrefix":"language-","inlineCodeMarker":null,"aliases":{},"showLineNumbers":true,"noInlineHighlight":false}},"gatsby-remark-embed-video","gatsby-remark-video",{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"path/to/dir","ignoreFileExtensions":["png","jpg","jpeg","bmp","tiff"]}},{"resolve":"gatsby-remark-figure-caption"}],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/blog/blog","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/@raae/gatsby-remark-oembed/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://blog.hpprc.dev"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-149661454-2","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"hpp blog","short_name":"hpp blog","description":"Personal blog","start_url":"/?utm_source=homescreen","background_color":"#fff","theme_color":"#03030f","display":"standalone","icon":"contents/assets/icon.jpg","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"46c406df447048c01f98b937a5b0eb74"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"src/gatsby"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
